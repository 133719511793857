import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import MenuItem from './FaqMenuItem';
import Group from './FaqGroup';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {Row, Col, Drawer} from 'antd';
import {Heading} from '../../Widgets';
import {ToggleDrawer} from '../../Widgets';
const appConfig = require('../../data.json');

// labels structure
// {
//   display: "",
//   name: "",
//   children: [
//     {
//       display: "",
//       name: "",
//     },
//     ...
//   ]
// }

function FaqPage(props) {
  const app = React.useContext(AppContext.Context);
  const [selected, setSelected] = React.useState({
    major: null,
    sub: null,
  });
  const [records, setRecords] = React.useState([]);
  const {faqLabels: labels} = app.state;
  const [showDrawer, setShowDrawer] = React.useState(false);

  React.useEffect(() => {
    if (labels.length > 0 && labels[0].children.length > 0) {
      setSelected({
        major: labels[0].name,
        sub: labels[0].children[0],
      });
    }
  }, [labels]);

  React.useEffect(() => {
    async function fetchData() {
      if (selected.sub && selected.major) {
        app.actions.setLoading(true);
        let params = {
          $and: [
            {label: 'FAQ'},
            ...(selected && [{label: selected.major}]),
            selected ? {label: selected.sub.name} : {label: 'none'},
          ],
        };

        setRecords(await app.actions.getBlogs({query: params}));
        app.actions.setLoading(false);
      }
    }

    fetchData();
  }, [app.actions, selected]);

  /*
   * Direct jump to subpage with query string
   *  - major / sub
   *
   * eg. major=條款聲明 sub=隱私政策
   */
  React.useEffect(() => {
    if (typeof window != undefined && window.location.search) {
      try {
        const params = window.location.search
          .substring(1) // remove first '?'
          .split('&')
          .reduce((ret, curr) => {
            ret[curr.split('=')[0]] = decodeURI(curr.split('=')[1]);
            return ret;
          }, {});

        if (params['major'] != undefined && params['sub'] != undefined) {
          const flatLabelPair = labels.reduce((ret, p) => {
            p.children.forEach((c) => {
              ret.push([p.name + '__' + c.name, p.name, c]);
            });
            return ret;
          }, []);

          let targets = flatLabelPair.filter(
            (t) => t[0] === params['major'] + '__' + params['sub'],
          );
          if (targets.length > 0) {
            setSelected({
              major: targets[0][1],
              sub: targets[0][2],
            });
          }
        }
      } catch (err) {}
    }
  }, [labels]);

  return (
    <ContentWrapper>
      <Row gutter={{sm: 10, md: 10, lg: 30}} style={{marginBottom: 40}}>
        <Col xs={0} sm={10} md={7} lg={5}>
          {labels.map((label, idx) => (
            <MenuItem
              key={label.name}
              label={label.name}
              items={label.children}
              defaultExpand={true}
              selected={selected.sub}
              setSelected={(sub, major) => {
                setSelected({major, sub});
              }}
            />
          ))}
        </Col>
        <Col xs={24} sm={14} md={17} lg={19}>
          <Heading style={{fontSize: 24}}>{selected.sub?.display}</Heading>

          <Group key={selected.sub} records={records} />
        </Col>
      </Row>

      <ToggleDrawer onClick={() => setShowDrawer(true)} style={{color: '#fff'}}>
        FAQ
        <br />
        列表
      </ToggleDrawer>

      <Drawer
        title=""
        placement="left"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}>
        {labels.map((label, idx) => (
          <MenuItem
            key={label.name}
            label={label.name}
            items={label.children}
            defaultExpand={true}
            selected={selected.sub}
            setSelected={(sub, major) => {
              setSelected({major, sub});
              showDrawer(false);
            }}
          />
        ))}
      </Drawer>
    </ContentWrapper>
  );
}

export default FaqPage;
