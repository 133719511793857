import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../data.json');

export default function MenuItem(props) {
  const {label, items, selected, setSelected, defaultExpand} = props;
  const [expand, setExpand] = React.useState(defaultExpand);

  return (
    <Wrapper expand={expand}>
      <div className="label" onClick={() => setExpand(!expand)}>
        <div style={{flex: 1}}>{label}</div>
        <div>{expand ? '-' : '+'}</div>
      </div>

      <ul>
        {items.map((item, idx) => {
          const isSelected = selected === item;
          return (
            <li
              key={idx}
              className={`${isSelected ? 'selected' : ''}`}
              onClick={() => setSelected(item, label)}>
              {item.display}
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-top: 1px solid ${appConfig.colors.border};
  border-left: 1px solid ${appConfig.colors.border};
  border-right: 1px solid ${appConfig.colors.border};

  & > .label {
    border-bottom: 1px solid ${appConfig.colors.border};
    font-size: 18px;
    font-weight: 700;
    color: #555;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  & > ul {
    display: block;
    max-height: ${(props) => (props.expand ? '1000px' : '0px')};
    opacity: ${(props) => (props.expand ? 1 : 0)};
    pointer-events: ${(props) => (props.expand ? 'auto' : 'none')};
    transition: 200ms;

    & > li {
      list-style-type: none;
      border-bottom: 1px solid ${appConfig.colors.border};
      padding: 10px;
      font-size: 14px;
      cursor: pointer;
    }

    & > li.selected {
      color: ${appConfig.colors.main};
    }
  }
`;
